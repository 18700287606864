import {es} from "@formkit/i18n";
import {generateClasses} from "@formkit/themes";
import {genesisIcons} from "@formkit/icons";
import myTailwindTheme from "./tailwind-theme.js"; // change to your theme's path
import {plugin as inertiaPlugin} from "formkit-addon-inertia";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore.js";
import {createAutoHeightTextareaPlugin} from '@formkit/addons'


import autocomplete from "./Components/Formkit/autocompleteInput.js";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function antes_de(node, inputFechaFin) {
    // let fechaFinNodo = node.at(inputFechaFin);
    // console.log("antes_de", inputFechaFin, fechaFinNodo.value);
    const fechaFinNodo = node.at(inputFechaFin) ? node.at(inputFechaFin).value : null;
    if (fechaFinNodo === undefined || fechaFinNodo === null) return true;

    const fechaInicio = dayjs(node.value, "DD-MM-YYYY");
    const fechaFin = dayjs(node.at(inputFechaFin)?.value, "DD-MM-YYYY");

    // console.log(date1, date2, date1.isSameOrAfter(date2));
    return fechaInicio.isSameOrBefore(fechaFin);
}

function despues_de(node, inputFechaInicio) {
    // console.log(node.value, node.at(inputFechaInicio)?.value);
    // let fechaInicioNodo = node.at(inputFechaInicio);

    // console.log("despues_de", inputFechaInicio, fechaInicioNodo?.value);
    const fechaInicioNodo = node.at(inputFechaInicio) ? node.at(inputFechaInicio).value : null;
    // If there's no date available, return false
    if (fechaInicioNodo === undefined || fechaInicioNodo === null) {
        return false;
    }
    const fechaFinNode = node.value ? node.value : null;

    const fechaInicio = dayjs(fechaInicioNodo, "DD-MM-YYYY");
    const fechaFin = dayjs(fechaFinNode, "DD-MM-YYYY");

    // console.log(date1, date2, date1.isSameOrAfter(date2));
    return fechaFin.isSameOrAfter(fechaInicio);
}

// 👀 this is important otherwise this rule will not run when empty.
antes_de.skipEmpty = false;
despues_de.skipEmpty = false;

const legends = ["checkbox_multi", "radio_multi", "repeater", "transferlist"];

function addAsteriskPlugin(node) {
    if (
        ["button", "submit", "hidden", "group", "list", "meta"].includes(
            node.props.type,
        )
    )
    return;

    node.on("created", () => {
        const legendOrLabel = legends.includes(
            `${node.props.type}${node.props.options ? "_multi" : ""}`,
        )
            ? "legend"
            : "label";

        node.props.definition.schemaMemoKey = `required_${node.props.definition.schemaMemoKey}`;

        node.context.isRequired = node.props.parsedRules.some(
            (rule) => rule.name === "required",
        );

        node.on("prop:validation", () => {
            node.context.isRequired = node.props.parsedRules.some(
                (rule) => rule.name === "required",
            );
        });

        const schemaFn = node.props.definition.schema;
        node.props.definition.schema = (sectionsSchema = {}) => {
            sectionsSchema[legendOrLabel] = {
                children: [
                    "$label",
                    {
                        $el: "span",
                        if: "$isRequired",
                        attrs: {
                            class: "$classes.asterisk",
                        },
                        children: ["*"],
                    },
                ],
            };

            return schemaFn(sectionsSchema);
        };
    });
}

const config = {
    locales: {es},
    locale: "es",
    icons: {
        ...genesisIcons,
    },
    inputs: {
        myAutocomplete: autocomplete,
    },
    config: {
        classes: generateClasses(myTailwindTheme),
    },
    plugins: [inertiaPlugin, addAsteriskPlugin, createAutoHeightTextareaPlugin(),],
    rules: {
        despues_de,
        antes_de,
    },
    messages: {
        es: {
            validation: {
                despues_de: ({name, node, args: inputNames}) => {
                    // First we want to get the labels of the other inptus
                    const labels = inputNames
                        .map((name) => {
                            const dependentNode = node.at(name);
                            if (dependentNode) {
                                return (
                                    dependentNode.props.validationLabel ||
                                    dependentNode.props.label ||
                                    dependentNode.props.name ||
                                    String(dependentNode.name)
                                );
                            }
                            return false;
                        })
                        .filter((name) => !!name);
                    // labels.unshift(name);
                    return `${labels.join(
                        " ",
                    )}  debe de ser una fecha menor o igual`;
                },
                antes_de: ({name, node, args: inputNames}) => {
                    // First we want to get the labels of the other inptus
                    const labels = inputNames
                        .map((name) => {
                            const dependentNode = node.at(name);
                            if (dependentNode) {
                                return (
                                    dependentNode.props.validationLabel ||
                                    dependentNode.props.label ||
                                    dependentNode.props.name ||
                                    String(dependentNode.name)
                                );
                            }
                            return false;
                        })
                        .filter((name) => !!name);
                    // labels.unshift(name);
                    return `${labels.join(
                        " ",
                    )}  debe de ser una fecha mayor o igual`;
                    // return `${
                    //     node.at(inputNames)?.props.label
                    // }  debe de ser una fecha mayor o igual`;
                },
            },
        },
    },
};

export default config;
